* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
  /* background-image: linear-gradient(to right, #bdbdc5 0%, #a8a8af 100%);*/
  background-image: linear-gradient(to right, #757f9a 0%, #d7dde8 100%);
}

h1 {
  position: relative;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  background: -webkit-linear-gradient(rgb(40, 37, 37), rgb(0, 0, 213, 0.8));
  -webkit-background-clip: text;
  -webkit-text-fill-color:transparent;
  margin-bottom: 3rem;
  letter-spacing: 2px;
}


h1::after {
  content: '';
  display: block;
  width: 400px;
  height: 2px;
  background-color: #ff6c2c;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
}

p {
  font-size: 24px;
  text-align: center;
  color: rgb(40, 37, 37);
}

@media (max-width: 1024px) {

  h1 {
    position: relative;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 3rem;
    margin-top: 2rem;
    letter-spacing: 5.3px;
  }

  h1::after {
    width: 250px;
  }
}

@media only screen and (min-width:1640px) {

  /* Стилове за екрани с ширина 1200 пиксела и по-голяма */
  h1 {
    position: relative;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 4rem;
    font-size: 2.5rem;
    letter-spacing: 3px;
  }

  h1::after {
    width: 500px;
  }
}