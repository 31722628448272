.container-home {
  height: 100vh;
  margin-top: 2.2rem;
  display: flex;
  justify-content: center;
}

.container-home-left {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 45%;
}

.container-home-right {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 45%;
}

.text-bo {
  font-size: 2rem;
  margin-bottom: 20.5rem;
  margin-left: 50px;
  color: #192439;
}

#text-front-end {
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 2.8px;
}

.text-developer {
  font-size: 1.5rem;
  text-align: center;
}

.home-button {
  display: flex;
  justify-content: space-evenly;
}

.home-social-icons {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

#animated-text {
  animation: fadeIn 1s ease-out;
}

@keyframes gradientText {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}

.animated-gradient-text {
  background: linear-gradient(to right, #f0f2f0 0%, #192439 100%);
  background-size: 200% 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: gradientText 5s linear infinite;
}

.container-z {
  background: linear-gradient(318deg, rgb(99, 99, 101) 0%, rgb(232, 232, 242) 45%, rgb(99, 99, 101) 100%);
  z-index: -1;
  height: 280px;
  margin-left: 40px;
  margin-bottom: 50px;
  border-radius: 8px;
  box-shadow: 0 0 60px rgba(119, 71, 195, 0.1);
  /* Добавя тен към контейнера */
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.circle-container {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 60px;
  position: relative;
  margin-top: 40px;
  margin-left: 100px;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container-education {
  margin-top: 4rem;
}

.container-about {
  margin-top: 11rem;

}

.container-contacts {
  margin-top: 12rem;
  margin-bottom: 5rem;
  min-height: 40vh;
  max-height: 100vh;
}


@media only screen and (max-width:2641px) {

  /* Стилове за екрани с ширина 1200 пиксела и по-голяма */
  .container-z {
    width: calc(100% - 80px);
    /* 80px = 40px (от ляво) + 40px (от дясно) */
    margin-left: 0;
    margin-top: 18%;
  }
}


@media only screen and (min-width:1640px) {

  /* Стилове за екрани с ширина 1200 пиксела и по-голяма */
  .container-z {
    width: calc(100% - 80px);
    /* 80px = 40px (от ляво) + 40px (от дясно) */
    margin-left: 0;
    margin-top: 18%;
    height: 425px;
  }

  .circle-container {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;
    margin-top: 110px;
    margin-left: 200px;
  }

  .text-bo {
    font-size: 3rem;
    margin-bottom: 30rem;
  }

  .container-home-right {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 43%;
  }

  #text-front-end {
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 2.8px;
  }
  
  .text-developer {
    font-size: 1.8rem;
  }

  .container-contacts {
    min-height: 68vh;
    min-height: 70vh;
    margin-top: 14rem;
  }

  .container-about {
    margin-top: 13rem;
  }

  .container-education {
    margin-top: 0.8rem;
  }
}