.about-text-first p {
    text-align: center;
    width: 70%;
    margin: 0 auto;
}

.about-text {
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
    color: #192439;
}

.container-about-items {
    margin-top: 6rem;
    padding: 0;
    display: flex;
    justify-content: space-around;
}

.container-about-items-left {
    width: 40%;
}

.container-about-items-right {
    width: 40%;
}

@media (max-width: 1024px) {

    .about-info {
        width: 80%;
        margin: 0 auto;
    }

    .about-text {
        font-size: 2rem;
        font-weight: bold;
    }

    .container-about-items {
        margin-top: 3rem;

        /* Уверете се, че няма padding на родителя */
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .container-about-items-left {
        width: 100%;


    }

    .container-about-items-right {
        margin-top: 3rem;
        width: 100%;
    }

}