.skills-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.html-icon {
  color: #e34c26;
  /* Цвят за HTML икона */
  font-size: 70px;
}

.css-icon {
  color: #1572b6;
  /* Цвят за CSS икона */
  font-size: 70px;
}

.js-icon {
  color: rgb(255, 223, 0);
  /* Цвят за JavaScript икона */
  font-size: 70px;
}

.bootstrap-icon {
  color: #7952b3;
  /* Цвят за Bootstrap икона */
  font-size: 80px;
}

.react-icon {
  color: #61dafb;
  /* Цвят за React икона */
  font-size: 70px;
}



@media (max-width: 1024px) {

  .icon {
    padding: 7px;
  }

  .html-icon {
    font-size: 50px;
  }

  .css-icon {
    font-size: 50px;
  }

  .js-icon {
    font-size: 50px;
  }

  .bootstrap-icon {
    font-size: 50px;
  }

  .react-icon {
    font-size: 50px;
  }
  
  .skills-container {
    justify-content: space-evenly;
  }
}