  @media (max-width: 1024px) {
    .container-main {
      min-height: auto;
      max-height: none;
    }

    .container-home {
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 90%;
      margin: 0 auto;
      text-align:center;
    }

    .container-home-left {
     margin-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 100%;
    }

    .circle-container {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      overflow: hidden;
      margin-top: 30px;
      margin-left: 30px;
    }

    .container-z {
      background: linear-gradient(318deg, rgb(99, 99, 101) 0%, rgb(236, 236, 243) 45%, rgb(53, 53, 56) 100%);
      z-index: -1;
      width: 0px;
      height: 0px;
      margin-left:-38%;
      top: 200px;
      left: 55%;
      transform: translateX(-50%);
    }

    .text-bo {
      margin-top: -30%;
      font-size: 1.8rem;
      margin-left: 0.2%;
    }

    .container-home-right {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 100%;
      height: 500px;
    
    }

    #text-front-end {
      font-size: 2rem;
      text-align: center;

    }

    .text-developer {
      font-size: 1.4rem;
      text-align: center;
    }

    .home-button {
      display: flex;
      flex-direction: column;
      width: 80%;
      margin: 0 auto;
    }

    .home-social-icons {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      margin-top: 50px;
    }

    .container-education {
      margin-top: 5rem;
      width: 100%;
    }

    .container-about {
      margin-top: 9.7rem;
    }

    .container-contacts {
      margin-top: 11.2rem;
      margin-bottom: 5rem;
    }
  }