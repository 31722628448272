footer {
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    /* При необходимост добавете отстъпи */
    text-align: center;
    /* Центрирайте съдържанието на футъра */
    background: rgb(25, 36, 57, 1);
    color: white;
}