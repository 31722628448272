form {
  text-align: center;
  margin: 20px auto;
  width: 80%;
}

input,
textarea {
  border: 0;
  outline: 0;
  padding: 1em;
  border: 1px solid silver;
  border-radius: 8px;
  display: block;
  width: 100%;
  margin-top: 1em;
  font-family: 'Merriweather', sans-serif;
  resize: none;
}

#input-submit {
  color: white;
  background: rgb(25,36,57);
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: 0px 17px 31px 0px rgba(53, 53, 53, 0.95);
  border: none;
}

#input-submit:hover {
  opacity: 0.3;
  transition: 0.5s;
}

@media only screen and (min-width:1640px) {

  /* Стилове за екрани с ширина 1200 пиксела и по-голяма */
  form {
    text-align: center;
    margin: 20px auto;
    width: 80%;
  }

  input,
  textarea {
    border: 0;
    outline: 0;
    padding: 1.8em;
    margin: 2em;
    border: 1px solid silver;
    border-radius: 8px;
    display: block;
    width: 100%;
    margin-top: 1em;
    font-family: 'Merriweather', sans-serif;
    resize: none;
  }

}