.container-call {
    width: 70%;
    margin: 0 auto;
    height: 180px;
    
    background-color: rgba(255, 255, 255, 0.9);
   
    box-shadow: 0px 17px 31px 0px rgba(53, 53, 53, 0.95);
    border: 1px solid silver;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    border-radius: 8px;
   
}


@media only screen and (min-width:1640px) {

    /* Стилове за екрани с ширина 1200 пиксела и по-голяма */
 

    .container-call {
        width: 80%;
        margin: 0 auto;
        height: 260px;
        
        background-color: rgba(255, 255, 255, 0.8);
      
        box-shadow: 0px 17px 31px 0px rgba(53, 53, 53, 0.95);
        border: 1px solid silver;
        display: flex;
       
    }

    .call-text {
        color: #0000ed;
        font-size: 2.5rem;
    }
}