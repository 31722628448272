
  
  .logo {
    margin-top: -1.3rem;
    width: 30px; /* Регулирайте спрямо вашите предпочитания */
    height: auto;
    margin-right: 5rem;
    border-radius: 8px;
    position:absolute;
  }

.menu {
    position: fixed;
    margin-top: 0;
    height: 60px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgb(25, 36, 57, 0.8);
    box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
    z-index: 20;
}

ul {
    list-style-type: none;
    display: flex;
    justify-content: space-evenly;
    text-align: left;
    padding: 10px;
    width: auto;
}

ul li {
    list-style: none;
    margin: 0 20px;
    transition: 0.5s;
}

ul li a {
    display: block;
    position: relative;
    text-decoration: none;
    padding: 5px;
    font-size: 16px;
    font-family: sans-serif;
    color: #fff;
    text-transform: uppercase;
    transition: 0.5s;
    margin-left: 50px;
}

ul:hover li a {
    transform: scale(1.5);
    opacity: 0.2;
    filter: blur(5px);
}

ul li a:hover {
    transform: scale(1.5);
    opacity: 1;
    filter: blur(0);
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}

ul li a:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsl(19, 89%, 51%);
    transition: 0.5s;
    transform-origin: right;
    transform: scaleX(0);
    z-index: -1;
}

ul li a:hover:before {
    transition: transform 0.5s;
    transform-origin: left;
    transform: scaleX(1);
}

#button-menu {
    position: absolute;
    right: 10px;
    /* Променете този размер, за да зададете разстоянието отдясно */
    top: 14px;
    /* Променете този размер, за да зададете разстоянието отгоре */
    color: white;
    background: none;
    font-size: 2rem;
    border: unset;
}

@media (min-width: 1024px) {
    .d-hide {
        display: none !important;
    }
}

@media (max-width: 1024px) {

    .m-hide {
        display: none !important;
    }

    .logo-container {
        display: none ;
     }
    


    .menu {
        background-color: rgb(25, 36, 57, 1);
    }

    .logo {
        margin-top: 0.3rem;
        width: 30px; /* Регулирайте спрямо вашите предпочитания */
        height: auto;
        
      }

    .menu ul {
        flex-direction: column;
        margin-top: 244px;
    }

    .menu a {
        text-decoration: none;
        color: white;
        font-size: 18px;
        padding: 16px;
        border-bottom: 1px solid white;
    }

    ul:hover li a {
        transform: scale(1.2);
    }

    .menu-item {
        width: 420px;
        background-color: rgb(25, 36, 57, 1);
    }
}