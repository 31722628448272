.container-education-text {
  padding: 20px;
  text-align: center;
  width: 90%;
  margin: 0 auto;
}

img {
  width: 100%;
}

.certificate-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 60px;
  /* Променете според вашите нужди */
}

.certificate {
  width: 100%;
  /* Регулирайте според вашите нужди и дизайн */
  max-width: 300px;
  /* Регулирайте според вашите нужди и дизайн */
  margin-bottom: 20px;
}

.certificate-image {
  transition: transform 0.3s ease-in-out;
}

.certificate-image:hover {
  transform: scale(2);
  /* Можете да промените мащаба според вашите предпочитания */
  cursor: pointer;
}



@media (max-width: 1024px) {

  .container-education-info {
    width: 90%;
    margin: 0 auto;
  }

  .certificate-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .certificate {
    width: 100%;
    max-width: 250px;
  }

  .certificate-image:hover {
    transform: scale(1);
  }
}

  @media only screen and (min-width:1640px) {

    .certificate {
      width: 100%;
      /* Регулирайте според вашите нужди и дизайн */
      max-width: 350px;
      /* Регулирайте според вашите нужди и дизайн */
      margin-bottom: 20px;
    }
  }