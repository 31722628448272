.main-container-contacts {
    display: flex;
    justify-content: space-evenly;
}

.container-contacts-left {
    width: 30%;
}

.call-me {
    margin-top: 19px;
}

.container-contacts-icons {
    margin-top:23px;
    display: flex;
    justify-content: space-evenly;
}

.container-contacts-right {
    width: 50%;
}

@media (max-width: 1024px) {
    .main-container-contacts {
        display: flex;
        flex-direction: column;
    }

    .container-contacts-left {
        width: 100%;
        margin-bottom: 4rem;
    }

    .container-contacts-right {
        width: 95%;
        margin: 0 auto;
    }
}

@media only screen and (min-width:1640px) {

    /* Стилове за екрани с ширина 1200 пиксела и по-голяма */
    .container-contacts-icons {
        margin-top: 72px;
        display: flex;
        justify-content: space-evenly;
    }
}